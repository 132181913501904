<template>
  <div @click="$emit('click')">
    <v-lazy min-height="120px">
      <div class="dishItem">
        <div class="dishLabel">
          <div class="dishName">
            {{ dish.code }}. {{ dish.dishName }}
          </div>
          <div class="dishDesc" v-html="dish.dishDesc ? dish.dishDesc : 'Keine Beschreibung'"></div>
          <div class="dishPrice">{{ dish.price }} €</div>
        </div>
        <v-card
            style="border-radius: 8px" class="dishImage" elevation="0"
            v-if="hasImage"
        >
          <v-img
              height="80" width="120" contain
              :src="imageUrl"
          ></v-img>
        </v-card>
      </div>
    </v-lazy>
    <v-divider></v-divider>
  </div>
</template>

<script>
export default {
  name: "DishCard",
  props: {
    imageRoot: {},
    dish: {},
    inCart: {
      default: false
    }
  },
  computed: {
    hasImage() {
      const image = this.dish.image
      return this.dish.cloudImage ?? (image && ['.png', 'jpg', 'jpeg'].some(it => image.toLowerCase().endsWith(it)))
    },
    imageUrl() {
      return this.dish.cloudImage ?? (this.imageRoot + this.dish.image)
    }
  },
  components: {}
}
</script>

<style scoped>

</style>
