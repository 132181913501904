<template>
  <div>
    <v-app-bar v-if="$vuetify.breakpoint.mdAndUp" app :dark="barTransparent"
               :style="barTransparent? 'box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 20%);': ''"
               :color="barTransparent?'transparent':'white'" v-scroll="pageScroll">
      <v-spacer></v-spacer>
      <div class="d-flex align-center" style="width: 100vw;max-width: 1200px">

        <div class="d-flex">
          <v-btn text v-if="GlobalConfig.shopHomepageUrl">
            <v-icon left size="24">mdi-home</v-icon>
            <div class="ml-3 font-weight-bold d-flex align-center">
              <a :href="GlobalConfig.shopHomepageUrl"
                 :style="barTransparent ? {'color': 'white'}:{'color':'#212121'}"
                 style="text-decoration: none;">{{ $t('homePage') }}</a></div>
            <v-spacer/>
          </v-btn>

          <template v-else>
            <version-info></version-info>
          </template>

          <v-btn
              text
              class="ml-2"
          >
            <v-icon :color="isOpen?'':'error'" left>mdi-clock-outline</v-icon>
            {{ $t('Heute geoeffnet:') }}<span class="font-weight-bold">{{ todayOpenTimeDisplay }}</span>
          </v-btn>
        </div>

        <v-spacer></v-spacer>
        <div v-if="hasAddress" class="mr-4">
          <v-btn id="showPickInfo" @click="showAddressDialog = true" color="primary">
            <div v-if="isPickUp">
              <v-icon left>mdi-walk</v-icon>
              {{ $t('Abholung') }}
            </div>
            <div v-else>
              <v-icon left>mdi-truck-fast</v-icon>
              {{ addressInfo.addressLine1 }}
            </div>
          </v-btn>
        </div>
        <v-menu :offset-y="$vuetify.breakpoint.lgAndUp" :offset-x="$vuetify.breakpoint.mdOnly" left
                v-model="openCart"
                :close-on-content-click="false">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" class="pa-2 px-4" v-on="on">
              <v-icon left size="24" class="mr-4">mdi-basket-outline</v-icon>
              <span v-if="dishCount>0 && differentPrice < 0 && !isPickUp">{{
                  $t('Bitte noch')
                }} {{ -differentPrice | priceDisplay }} €</span>
              <span v-else-if="dishCount>0 && differentPrice > 0 && !isPickUp">{{
                  dishCount
                }} {{ $t('in der WarenKorb') }}</span>
              <span v-else-if="dishCount>0 && isPickUp">{{ dishCount }} {{
                  $t('in der WarenKorb')
                }}</span>
              <span v-else>{{ $t('Cart') }}</span>
            </v-btn>
          </template>

          <!--cart dropdown card -->
          <v-card v-if="dishCount > 0">
            <v-toolbar elevation="0">
              <v-card-title>{{ $t('Cart') }}</v-card-title>
              <v-spacer></v-spacer>
              <v-btn text @click="cart.clear()"> {{ $t("clear") }}
                <v-icon color="red">mdi-delete</v-icon>
              </v-btn>
            </v-toolbar>

            <v-divider></v-divider>
            <div style="overflow-y: scroll; max-height: 500px">
              <v-list style="padding: 0" three-line dense max-width="400">
                <edit-dish-card :image-root="GlobalConfig.imageRoot" v-for="dish in cartList" :key="dish.id"
                                :dish="dish"
                                :in-cart="true"></edit-dish-card>
              </v-list>
            </div>
            <cart-button :minimum-delivery-fee="startPrice" :is-pick-up="isPickUp"
                         :different-price="differentPrice" :total-price="totalPriceBeforeDiscount"
                         :today-open-time-display="todayOpenTimeDisplay"></cart-button>
          </v-card>
        </v-menu>
        <toolbar-language/>
      </div>
      <v-spacer></v-spacer>
    </v-app-bar>

    <div style="width: 100%;position: relative;height: 440px;margin-top: -64px">
      <v-img height="100%" gradient="to top right, rgba(0,0,0,.13), rgba(0,0,0,.40)"
             :src="imgUrl"></v-img>
      <div :style="{ bottom:$vuetify.breakpoint.smAndDown?'16px':'64px' }"
           style="position: absolute;bottom: 16px;color: white;text-align: left;width: calc(100vw - 32px);left: 16px"
           class="d-flex justify-center">
        <div style="max-width: 1200px;width: 100%">
          <div style="font-size: 2rem;font-weight: 900;font-family: 'Work Sans', sans-serif;">
            {{ restaurantInfo.name }}
          </div>
          <div v-html="restaurantInfo.takeawayAnnouncementHead ? restaurantInfo.takeawayAnnouncementHead : restaurantInfo.buffetAnnouncementHead" style="font-size: medium;font-weight: 400;font-family: 'Work Sans', sans-serif;margin-top: -4px">
          </div>
          <div class="d-flex mt-6">
            <!--            mainPage startPrice display-->
            <v-btn v-if="parseInt(startPrice) >= 0" small>{{ $t('MBW:') }} {{
                startPrice | priceDisplay
              }} €
            </v-btn>
            <v-btn class="ml-2" small v-if="pickupDiscount">{{ $t('Abholung Discount:') }}{{ pickupDiscount }}</v-btn>
          </div>
        </div>
      </div>
    </div>

    <div
        style="margin: 1.5rem 16px"
        v-if="$vuetify.breakpoint.smAndDown">
      <div class="listItem">
        <v-icon color="warning" size="18px">mdi-emoticon-happy</v-icon>
        <div class="ml-5" style="font-size: small;font-weight: 400">Sehr gut 8.4</div>
        <v-spacer></v-spacer>
        <v-btn tile small icon>
          <v-icon size="16px">mdi-heart-outline</v-icon>
        </v-btn>
      </div>
      <div class="listItem">
        <v-icon :color="isOpen?'':'error'" size="18px">mdi-clock-outline</v-icon>
        <div class="ml-5" style="font-size: small;font-weight: 400">{{ todayOpenTimeDisplay }}</div>
        <v-spacer></v-spacer>
        <v-btn @click="showRestaurantInfo=true" class="rounded infoButton" small elevation="0" style="">
          {{ $t('Weitere Infos') }}
        </v-btn>
      </div>
      <div class="listItem" v-if="hasAddress">
        <template v-if="isPickUp">
          <v-icon size="18px">mdi-walk</v-icon>

          <span class="ml-5" style="font-size: small;font-weight: 400">{{ $t('Abholung') }}</span>
          <v-spacer></v-spacer>
          <v-btn @click="showAddressDialog=true" class="rounded infoButton" small elevation="0" style="">
            {{ $t('changeInfo:') }}
          </v-btn>
        </template>
        <template v-else>
          <v-icon size="18px">mdi-truck-fast</v-icon>
          <span class="ml-5" style="font-size: small;font-weight: 400">{{ $t('Liefer:') }}</span>
          <span class="ml-2" style="font-size: small;font-weight: 400">{{ addressInfo.addressLine1 }}</span>
          <v-spacer></v-spacer>
          <v-btn @click="showAddressDialog=true" class="rounded infoButton" small elevation="0" style="">{{
              $t('changeInfo:')
            }}
          </v-btn>
        </template>
      </div>
      <v-text-field @click="$vuetify.goTo('#search',{offset:30})" id="search" hide-details class="mt-4"
                    v-model="searchWord"
                    dense outlined background-color="#f6f6f6"
                    :placeholder="$t('Suchen')"
                    prepend-inner-icon="mdi-magnify"></v-text-field>
    </div>
    <v-card v-else class="d-flex mt-n8 mx-auto pa-4 align-center" max-width="1200px" width="calc(100vw - 64px)">
      <div>
        <div class="d-flex align-center">
          <v-icon color="warning" size="40">mdi-emoticon-happy</v-icon>
          <div class="ml-3" style="font-size: large;font-weight: 400">8.4 <span class="caption">von 10</span></div>

          <v-btn color="grey" outlined class="ml-6" large>
            <v-icon left>mdi-heart-outline</v-icon>
            Favorit
          </v-btn>
        </div>
      </div>


      <v-spacer></v-spacer>
      <div>
        <v-text-field @click="$vuetify.goTo('#search',{offset:30})" id="search" hide-details class="ma-2"
                      v-model="searchWord"
                      dense outlined background-color="#f6f6f6"
                      :placeholder="$t('Suchen')"
                      prepend-inner-icon="mdi-magnify"></v-text-field>
      </div>
    </v-card>

    <template v-if="$vuetify.breakpoint.smAndDown">
      <v-btn elevation="0" large v-if="searchWord" @click="searchWord=''" block>
        <v-icon>mdi-close</v-icon>
        {{ $t('Reset Suchen') }}
      </v-btn>
      <v-divider></v-divider>
      <v-banner single-line app color="white" sticky>
        <v-chip-group>
          <v-chip v-for="category in categoryWithDishesInfo"
                  @click="gotoCategory(category)"
                  class="categoryChipItem" :key="category.id" color="white">
            {{ category.name }}
          </v-chip>
        </v-chip-group>
        <template v-slot:actions>
          <v-btn
              icon
              @click="expandAllCategories = !expandAllCategories"
          >
            <v-icon v-if="expandAllCategories">mdi-arrow-up-drop-circle-outline</v-icon>
            <v-icon v-else>mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
      </v-banner>
      <v-dialog v-model="expandAllCategories">
        <v-card style="max-height: 450px; overflow-y: scroll;position: relative">
          <v-btn
              style="position: absolute;right: 16px;top:16px"
              icon
              @click="expandAllCategories = false"
          >
            <v-icon>
              mdi-close-circle
            </v-icon>
          </v-btn>
          <v-card-title class="categoryTitle">
            {{ $t("Category") }}
          </v-card-title>

          <v-card-text>
            <div v-if="noDrinkCategory !== []">
              <h4 class="mt-6">{{ $t("Food") }}</h4>
              <v-chip-group column>
                <v-chip v-for="category in noDrinkCategory"
                        outlined
                        @click="gotoCategory(category); expandAllCategories = false"
                        class="categoryChipItem" :key="category.id">
                  {{ category.name }}
                </v-chip>
              </v-chip-group>
            </div>
            <div v-if="drinkCategory!==[]">
              <h4 class="mt-6">{{ $t("Drink") }}</h4>
              <v-chip-group column>
                <v-chip v-for="category in drinkCategory"
                        outlined
                        @click="gotoCategory(category); expandAllCategories = false"
                        class="categoryChipItem" :key="category.id">
                  {{ category.name }}
                </v-chip>
              </v-chip-group>
            </div>
          </v-card-text>
        </v-card>

      </v-dialog>
      <div v-if="!searchWord" class="itemContainer">
        <div :key="category.id" v-for="category in categoryWithDishesInfo">
          <div :id="'category'+category.id"></div>
          <div class="categoryContainer">
            <div class="categoryTitle">{{ category.name }}</div>
            <div class="categoryDesc">{{ category.desc }}</div>
          </div>
          <v-divider light></v-divider>
          <dish-card @click="dishClick(dish)"
                     v-for="dish in category.dishes" :key="dish.id"
                     :image-root="GlobalConfig.imageRoot"
                     :dish="dish"/>
        </div>
      </div>
      <div v-else class="itemContainer">
        <dish-card @click="dishClick(dish)"
                   v-for="dish in filteredDish" :key="dish.id"
                   :image-root="GlobalConfig.imageRoot"
                   :dish="dish"/>
        <div style="width: 100%;height: 600px"></div>
      </div>
      <v-btn @click="$router.push('/checkout')"
             v-if="dishCount > 0 && differentPrice >= 0 && !isPickUp" dark x-large
             class="mainButton"
             style="position: fixed;bottom: 16px; width: 90vw; left :5vw;z-index: 100">
        <v-icon left>mdi-shopping-outline</v-icon>
        <span
            style="font-size: 14px !important;text-transform: capitalize;font-weight: 500;letter-spacing: 0">{{
            $t('Zur Kasse')
          }}</span>
        <v-spacer></v-spacer>
        <div>{{ totalPriceBeforeDiscount | priceDisplay }} €</div>
      </v-btn>
      <v-btn v-else-if="dishCount>0 && differentPrice < 0 && !isPickUp" disabled x-large
             class="mainButton"
             style="position: fixed;bottom: 16px; width: 90vw; left :5vw;z-index: 100">
        <span style="font-size: 14px !important;text-transform: capitalize;font-weight: 500;letter-spacing: 0">
          {{ $t('Bitte noch') }} {{ -differentPrice | priceDisplay }} €</span>
      </v-btn>
      <v-btn @click="$router.push('/checkout')" v-else-if="dishCount>0 && isPickUp" x-large dark
             class="mainButton"
             style="position: fixed;bottom: 16px; width: 90vw; left :5vw;z-index: 100">
        <v-icon left>mdi-shopping-outline</v-icon>
        <span
            style="font-size: 14px !important;text-transform: capitalize;font-weight: 500;letter-spacing: 0">{{
            $t('Zur Kasse')
          }}</span>
        <v-spacer></v-spacer>
        <div>{{ totalPriceBeforeDiscount | priceDisplay }} €</div>
      </v-btn>
    </template>
    <template v-else>
      <div v-if="!searchWord">
        <div style="max-width: 1200px;width: 100vw;display: grid;grid-template-columns: 13.5% 4% 53.5% 4% 25%;"
             class="mx-auto mt-6">
          <div>
            <div style="position: relative; position: -webkit-sticky;position: sticky;top:72px;cursor: pointer">
              <div v-for="category in categoryWithDishesInfo"
                   @click="gotoCategory(category)"
                   class="categoryChipItem" style="max-width: 130px"
                   :key="category.id" color="white">
                {{ category.name }}
              </div>
            </div>
          </div>
          <div></div>
          <div>
            <div class="itemContainer">
              <div :key="category.id" v-for="category in categoryWithDishesInfo">
                <div :id="'category'+category.id"></div>
                <div class="categoryContainer">
                  <div class="categoryTitle">{{ category.name }}</div>
                  <div class="categoryDesc" v-if="category.desc">{{ category.desc }}</div>
                </div>
                <v-divider light></v-divider>
                <dish-card @click="dishClick(dish)"
                           v-for="dish in category.dishes" :key="dish.id"
                           :image-root="GlobalConfig.imageRoot"
                           :dish="dish"/>
              </div>
            </div>
          </div>
          <div></div>
          <div class="pa-4" style="position: relative;padding-bottom: 72px;">
            <div style="font-size: xx-large;font-weight: 900;font-family: 'Work Sans', sans-serif;line-height: 100%">
              {{ restaurantInfo.name }}
            </div>
            <blockquote v-html="restaurantInfo.takeawayAnnouncementHead ? restaurantInfo.takeawayAnnouncementHead : restaurantInfo.buffetAnnouncementHead" class="mt-4"></blockquote>
            <div v-html="restaurantInfo.takeawayAnnouncementBody ? restaurantInfo.takeawayAnnouncementBody : restaurantInfo.buffetAnnouncementBody" class="d-flex align-center mt-4" style="font-size: small;">
            </div>
            <h3>{{ $t('Adresse') }}</h3>
            <div class="mt-2" style="font-size: small">
              <p>{{ restaurantInfo.adress1 }}<br>
                {{ restaurantInfo.postCode }} {{ restaurantInfo.city }}</p>
            </div>
            <h3>{{ $t('Contact') }}</h3>
            <div class="mt-2" style="font-size: small">
              <p>{{ $t('Phone Number:') }} {{ restaurantInfo.telephone }}</p>
              <p>{{ $t('Email:') }} {{ restaurantInfo.emailAddress }}</p>
            </div>
            <h3>{{ $t('openingTimes') }}</h3>

            <div class="mt-2" style="font-size: small">
              <div v-for="(item, index) in openingTimeList" :key="index" class="d-flex mt-1 justify-space-between">
                <div class="font-weight-bold">
                  {{ item.weekday }}
                </div>
                <div class="text-right">
                  <span v-for="(timeItemForAll, n) in item.openTime" :key="n">
                      {{ timeItemForAll.join(' - ') }}<br>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div style="max-width: 1200px;width: 100vw;display: grid;grid-template-columns: 13.5% 4% 53.5% 4% 25%;"
             class="mx-auto mt-6">
          <div>
          </div>
          <div></div>
          <div>
            <div class="itemContainer">
              <div v-if="filteredDish.length !== 0">
                <v-btn elevation="0" large v-if="searchWord" @click="searchWord=''" block>
                  <v-icon>mdi-close</v-icon>
                  {{ $t('Reset Suchen') }}
                </v-btn>
                <dish-card @click="dishClick(dish)"
                           v-for="dish in filteredDish" :key="dish.id"
                           :image-root="GlobalConfig.imageRoot"
                           :dish="dish"/>
                <div style="width: 100%;height: 600px"></div>
              </div>
              <div v-else>
                <lottie-animation v-cloak
                                  ref="animation"
                                  :animationData="require('@/assets/empty.json')"/>
                <div class="d-flex justify-center">
                  <v-card-title class="text-h5">
                    {{ $t('nothingFound') }}
                  </v-card-title>
                  <v-card-actions>
                    <v-btn color="primary" text @click="searchWord=''">
                      {{ $t('clear') }}
                    </v-btn>
                  </v-card-actions>
                </div>
              </div>
            </div>
          </div>
          <div></div>
          <div class="pa-4" style="position: relative;padding-bottom: 72px;">
            <div style="font-size: xx-large;font-weight: 900;font-family: 'Work Sans', sans-serif;line-height: 100%">
              {{ restaurantInfo.name }}
            </div>
            <blockquote v-html="restaurantInfo.takeawayAnnouncementHead ? restaurantInfo.takeawayAnnouncementHead : restaurantInfo.buffetAnnouncementHead" class="mt-4"></blockquote>
            <div v-html="restaurantInfo.takeawayAnnouncementBody ? restaurantInfo.takeawayAnnouncementBody : restaurantInfo.buffetAnnouncementBody" class="d-flex align-center mt-4" style="font-size: small;">
            </div>
            <h3>{{ $t('Adresse') }}</h3>
            <div class="mt-2" style="font-size: small">
              <p>{{ restaurantInfo.adress1 }}<br>
                {{ restaurantInfo.postCode }} {{ restaurantInfo.city }}</p>
            </div>
            <h3>{{ $t('Contact') }}</h3>
            <div class="mt-2" style="font-size: small">
              <p>{{ $t('Phone Number:') }} {{ restaurantInfo.telephone }}</p>
              <p>{{ $t('Email:') }} {{ restaurantInfo.emailAddress }}</p>
            </div>
            <h3>{{ $t('openingTimes') }}</h3>
            <div class="mt-2" style="font-size: small">
              <div v-for="(item, index) in openingTimeList" :key="index" class="d-flex mt-1 justify-space-between">
                <div class="font-weight-bold">
                  {{ item.weekday }}
                </div>
                <div class="text-right">
            <span v-for="(timeItemForAll, n) in item.openTime" :key="n">
                {{ timeItemForAll.join(' - ') }}<br>
            </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <address-selector @save="save" v-model="showAddressDialog"/>
    <dish-detail-sheet :show="showDishDetailSheet" @modification-cancel="cancelEdit"
                       @modification-submit="submitModification"
                       :image-root="GlobalConfig.imageRoot"
                       :dish="currentDish"></dish-detail-sheet>
    <dish-detail-dialog :show="showDishDialog" @modification-cancel="cancelDialog"
                        @modification-submit="submitModification"
                        :image-root="GlobalConfig.imageRoot"
                        :dish="currentDish"></dish-detail-dialog>
    <restaurant-detail-page
        @close="showRestaurantInfo=false"
        :show="showRestaurantInfo"
        :opening-time-list="openingTimeList"
        :is-open="isOpen"
        :today-open-time-display="todayOpenTimeDisplay"
        :restaurant-info="restaurantInfo">
    </restaurant-detail-page>

    <div v-if="$vuetify.breakpoint.mdAndUp">
      <div v-if="show">
        <v-dialog fullscreen persistent :value="!restaurantIsOpen">
          <v-card tile style="justify-content: center; display:flex;">
            <div>
              <lottie-animation v-cloak
                                style="height:50vh"
                                ref="animation"
                                :animationData="require('@/assets/closed.json')"/>
              <h1>{{ $t('Tut mir sehr leid!') }}😭</h1>
              <p class="mt-2">{{
                  $t('Zur Zeit nehmen wir keine Online-Bestellungen an, bei Fragen rufen Sie uns bitte direkt an.')
                }}</p>
              <v-card-actions>
                <p><span>☎️</span>️ <a class="ml-2" :href="'tel://'+restaurantInfo.telephone">
                  {{ restaurantInfo.telephone }}</a></p>
                <v-btn class="mb-4" text color="primary" @click="openTimeDialog = true">{{
                    $t('showOpeningTimes')
                  }}
                </v-btn>
              </v-card-actions>
              <p>Made by️ &nbsp;@InnerKen</p>
            </div>
          </v-card>
        </v-dialog>
      </div>
    </div>
    <div v-else>
      <div v-if="show">
        <v-dialog fullscreen :value="!restaurantIsOpen">
          <v-card tile>
            <v-fade-transition>
              <div class="pa-4">
                <lottie-animation v-cloak
                                  ref="animation"
                                  :animationData="require('@/assets/closed.json')"/>
                <h1>{{ $t('Tut mir sehr leid!') }}😭</h1>
                <p class="mt-2">{{
                    $t('Zur Zeit nehmen wir keine Online-Bestellungen an, bei Fragen rufen Sie uns bitte direkt an.')
                  }}</p>
                <v-card-actions>
                  <p><span>☎️</span>️ <a class="ml-2" :href="'tel://'+restaurantInfo.telephone">
                    {{ restaurantInfo.telephone }}</a></p>
                  <v-btn class="mb-4" text color="primary" @click="openTimeDialog = true">{{
                      $t('showOpeningTimes')
                    }}
                  </v-btn>
                </v-card-actions>
                <p>Made by️ &nbsp;@InnerKen</p>
              </div>
            </v-fade-transition>
          </v-card>
        </v-dialog>
      </div>
    </div>

    <v-dialog v-model="openTimeDialog">
      <v-card>
        <v-card-title class="text-h5 lighten-2">
          {{ $t('openingTimes') }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div class="mt-2">
            <div v-for="(item, index) in openingTimeList" :key="index" class="d-flex mt-1 justify-space-between">
              <div class="font-weight-bold">
                {{ item.weekday }}
              </div>
              <div class="text-right">
            <span v-for="(timeItemForAll, n) in item.openTime" :key="n">
                {{ timeItemForAll.join(' - ') }}<br>
            </span>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-btn v-if="!barTransparent" @click="$vuetify.goTo(0)" right bottom fixed fab>
      <v-icon>mdi-arrow-up</v-icon>
    </v-btn>
  </div>
</template>

<script>
import DishDetailSheet from '@/components/DishDetailSheet'
import Swal from 'sweetalert2'
import IKUtils from 'innerken-js-utils'
import { getCategoryWithDishes, getOpeningTime, getWeekDay, isInOpenTime } from '@/dataLayer/service/api'
import CartService from '@/dataLayer/service/CartService'
import { defaultRestaurantInfo, getCurrentRestaurantInfo } from '@/dataLayer/repository/restaurantInfo'
import DishCard from '@/components/DishCard'
import RestaurantDetailPage from "@/components/fragment/RestaurantDetailPage"
import dayjs from "dayjs"
import GlobalConfig from "@/dataLayer/repository/GlobalSettingManager"
import LottieAnimation from 'lottie-web-vue' // import lottie-web-vue
import DishDetailDialog from "@/components/DishDetailDialog"
import EditDishCard from "@/components/EditDishCard"
import ToolbarLanguage from "@/components/ToolbarLanguage"
import i18n from "@/i18n"
import AddressSelector from "@/pages/widget/adress/AddressSelector"
import CartButton from '@/components/CartButton'
import VersionInfo from '@/pages/widget/VersionInfo'
import { OrderInfo } from '@/dataLayer/repository/currentOrderState'
import { DeliveryMethod } from '@/dataLayer/service/OrderService'


export default {
  name: "MainPage",
  components: {
    VersionInfo,
    CartButton,
    AddressSelector,
    RestaurantDetailPage,
    DishCard,
    DishDetailSheet,
    LottieAnimation,
    DishDetailDialog,
    EditDishCard,
    ToolbarLanguage
  },
  data: function () {
    return {
      drinkCategory: [],
      noDrinkCategory: [],
      getFreePrice: 0,
      imgUrl: GlobalConfig.bannerPath ?? 'Resource/Image/banner.png',
      expandAllCategories: false,
      userAddress: undefined,

      addressInfo: null,
      deliveryMethod: null,


      showAddressDialog: false,
      startPrice: 0,
      openCart: false,
      openTimeDialog: false,
      showDishDetailSheet: false,
      showDishDialog: false,
      showRestaurantInfo: false,
      currentDish: null,
      categoryWithDishesInfo: [],
      restaurantInfo: defaultRestaurantInfo,
      cart: CartService,
      searchWord: null,
      openingTimeList: [], todayOpenTime: {}, isOpen: false,
      restaurantIsOpen: true,
      show: false,
      GlobalConfig,
      barTransparent: true
    }
  },
  watch: {
    minimumDeliveryFee (val) {
      return val
    }
  },
  computed: {
    hasAddress: function () {
      return this.addressInfo !== null
    },
    dishCount: function () {
      return this.cartList.length > 0 ? this.cart.count() : 0
    },
    cartList: function () {
      return this.cart.list?.length > 0 ? this.cart.list : []
    },
    totalPriceWithoutMod: function () {
      return this.cartList.length > 0 ? this.cart.total() : 0
    },
    totalPriceBeforeDiscount: function () {
      return parseFloat(this.totalPriceWithoutMod)
    },
    differentPrice: function () {
      return this.totalPriceBeforeDiscount - this.startPrice
    },
    todayOpenTimeDisplay: function () {
      return this.todayOpenTime?.openTime?.map(a => a.join('-')).join(', ') ?? i18n.t("Heute geschloss")
    },
    pickupDiscount: function () {
      return GlobalConfig.pickDiscountStr.replace('p', '%')
    },
    filteredDish: function () {
      return this.categoryWithDishesInfo.reduce((arr, i) => {
        arr.push(...i.dishes.filter(d => {
          return d.code.toLowerCase().includes(this.searchWord) || d.dishName.toLowerCase().includes(this.searchWord)
        }))
        return arr
      }, []).sort((a, b) => a.code > b.code ? 1 : -1)
    },
    isPickUp () {
      return this.deliveryMethod === DeliveryMethod.pickup
    }
  },
  methods: {
    dishClick (dish) {
      if (!this.hasAddress) {
        this.showAddressDialog = true
        return
      }
      this.currentDish = dish
      if (this.$vuetify.breakpoint.smAndDown) {
        this.showDishDetailSheet = true
      } else {
        this.showDishDialog = true
      }

    },
    save (addressInfo) {
      OrderInfo.address = addressInfo
      OrderInfo.deliveryRule = addressInfo.deliveryRule
      OrderInfo.deliveryMethod = addressInfo.deliveryMethod

      this.updateAllInfo()

      this.showAddressDialog = false
    },
    updateAllInfo () {
      this.addressInfo = OrderInfo.address
      this.deliveryMethod = OrderInfo.deliveryMethod
      this.deliveryCost = 0
      this.freePrice = 0
      this.startPrice = 0
      if (OrderInfo.deliveryRule) {
        const rule = OrderInfo.deliveryRule
        if (rule.priceMod) {
          this.deliveryCost = rule.priceMod
        }
        this.startPrice = rule.startPrice
        this.freePrice = rule.freePrice
      }
    },
    pageScroll () {
      this.barTransparent = window.scrollY <= 0
    },
    gotoCategory: function (category) {
      this.$vuetify.goTo(`#category${category.id}`, {offset: 90})
    },
    addDish: function (dish, count = 1) {
      this.updateCart(dish, count)
    },
    submitModification: function (mod, count = 1) {
      const apply = []
      const dish = this.currentDish
      if (dish.modInfo) {
        for (const i of dish.modInfo) {
          const item = {}
          item.groupId = i.id
          item.selectId = i.selectValue.filter((s, index) => {
            return [(mod[i.id] ?? [])].flat().includes(index)
          })
          if (i.required === '1' && item.selectId === '') {
            item.selectId = i.selectValue[0]
          }
          apply.push(item)
        }
        dish.apply = apply
      }

      this.addDishWithMod(dish, count)
      this.currentDish = null
      this.showDishDetailSheet = false
      this.showDishDialog = false
    },
    addDishWithMod: function (dish, count) {
      this.updateCart(dish, count)
    },
    updateCart: function (dish, count) {
      if (!Swal.isVisible()) {
        IKUtils.toast()
      }
      try {
        window.navigator.vibrate(100)
      } catch (e) {
        console.log(e)
      }

      setTimeout(() => {
        this.cart.add(dish, count)
      }, 3)
    },
    cancelEdit () {
      this.$nextTick(() => {
        this.showDishDetailSheet = false
      })
    },
    cancelDialog () {
      this.$nextTick(() => {
        this.showDishDialog = false
      })
    }
  },
  async mounted () {

    this.categoryWithDishesInfo = await getCategoryWithDishes('DE')
    this.drinkCategory = this.categoryWithDishesInfo.filter(i => i.dishesCategoryTypeId === "9")
    this.noDrinkCategory = this.categoryWithDishesInfo.filter(i => i.dishesCategoryTypeId !== "9")
    this.restaurantInfo = await getCurrentRestaurantInfo()
    this.openingTimeList = await getOpeningTime()
    this.todayOpenTime = (this.openingTimeList).find(item => parseInt(item.id) === parseInt(getWeekDay()))
    this.isOpen = isInOpenTime(dayjs().format('HH:mm'), this.todayOpenTime?.openTime)
    this.restaurantIsOpen = GlobalConfig.forceOpenWeb || (this.restaurantInfo?.currentlyOpening ?? true)
    if (!this.restaurantIsOpen) {
      setTimeout(() => {
        this.show = true
      }, 1000)
    }
    if (!OrderInfo.deliveryMethod) {
      this.showAddressDialog = true
    } else {
      this.updateAllInfo()
    }


  }
}
</script>

<style scoped>
.categoryChipItem {
  max-width: 200px;
  padding: 4px 8px;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  color: #707173;
  text-overflow: ellipsis;
  flex-shrink: 0;
  overflow: hidden;
}

.categoryChipItem:hover {
  background: #f6f6f6;
  border-radius: 16px;
}

.categoryChipItem:after {
  position: absolute;
  will-change: transform;
  left: 0;
  width: 100%;
  border-bottom: 2px solid #202125;
  transform: translate(-102%);
  transition: transform .4s
}

.categoryTitle {
  font-size: 1.5rem;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  text-transform: uppercase
}
</style>
