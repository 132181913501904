<template>
  <div>
    <div
        class="d-flex black flex-column align-start pt-10"
        style="width: 100%;position: relative;"
    >
      <v-spacer/>
      <v-btn
          @click="$router.back()"
          color="white"
          text
      >
        <v-icon left>mdi-arrow-left</v-icon>
        <span class="ml-1">{{ $t('back') }}</span>
      </v-btn>
    </div>
    <div class="container mt-4">
      <div>
        <v-card
            elevation="0"
            color="#eee"

            @click="showAddressSelector=true"
            class="d-flex align-center pa-4"
        >
          <v-icon v-if="isPickUp">mdi-walk</v-icon>
          <v-icon v-else>mdi-truck-fast</v-icon>
          <div class="ml-3">
            <template v-if="addressInfo==null">
              {{ $t('Liefermethode und Zeit') }}
            </template>
            <template v-else>
              <template>
                <div>{{ $t(deliveryMethod) }}</div>
                <div
                    style="font-size: 10px;letter-spacing: -0.2px"
                >
                  {{
                    isPickUp ? addressInfo.firstName + ' ' + addressInfo.lastName :
                        addressInfo.addressLine1 + '@' + addressInfo.firstName + ' ' +
                        addressInfo.lastName
                  }}
                </div>
              </template>
            </template>
          </div>
          <v-spacer></v-spacer>
          <v-icon>mdi-chevron-right</v-icon>
        </v-card>
      </div>
      <v-card
          elevation="0" color="#eee" @click="showDateArea=!showDateArea"
          class="d-flex mt-4 contentPadding align-center"
      >
        <v-icon>mdi-calendar-month-outline</v-icon>
        <div class="ml-3">
          <div>
            {{ $t('Pick Time / Delievery Time') }}
          </div>
          <div style="font-size: 10px;letter-spacing: -0.2px;">
            {{ futureWeek[selectedDay].text }}

            <span v-if="selectedTime"> , {{ selectedTime }} </span>
          </div>
        </div>
        <v-spacer></v-spacer>
        <v-icon>mdi-chevron-right</v-icon>
      </v-card>

      <div class=" mt-4 pb-2">
        <div>
          <v-icon color="black">mdi-food-fork-drink</v-icon>
        </div>
      </div>
      <edit-dish-card
          :image-root="GlobalConfig.imageRoot" v-for="dish in cartList" :key="dish.id" :dish="dish"
          :in-cart="true"
      ></edit-dish-card>
      <div v-if="note" class="dishDesc mt-2">{{ note }}</div>
      <div class="d-flex mt-4">
        <v-btn elevation="0" @click="$router.push('/')">
          <v-icon>mdi-plus</v-icon>
          <div class="ml-3">
            {{ $t('Mehr gerichte hintufügen') }}
          </div>
        </v-btn>
        <v-btn elevation="0" class="ml-2" @click="showCommentArea=!showCommentArea;note=commentStr">
          <v-icon>mdi-comment-text-outline</v-icon>
          <div class="ml-3">
            <div>{{ $t('Anmerkung für das Restaurant') }}</div>
          </div>
        </v-btn>
      </div>
      <div class=" mt-8 pb-2">
        <div>
          <v-icon>mdi-sale</v-icon>
        </div>
        <v-card
            elevation="0" color="grey lighten-4" @click="showDiscountDialog=!showDiscountDialog"
            class="d-flex pa-3 mt-4 align-center"
        >
          <div>
            <div>
              {{ $t('Add Discount Code') }}
            </div>
          </div>
          <v-spacer></v-spacer>
          <v-icon>mdi-chevron-right</v-icon>
        </v-card>
      </div>

      <div style="position: fixed;bottom: 0;left: 0;right: 0;" class="grey lighten-4 elevation-1">
        <template>
          <v-btn
              elevation="0"
              block
              x-large
              :disabled="!canOrder"
              @click="showPaymentMethod"
              color="primary lighten-4 black--text"
              :loading="loading"
          >
            <template v-if="canOrder">
              <v-icon left>mdi-shopping-outline</v-icon>
              <div>{{ totalPrice | priceDisplay }} €</div>
            </template>
            <template v-else-if="differentPrice<0">
              <div>
                {{ $t('Bitte noch') }} {{ -differentPrice | priceDisplay }} € {{ $t('toReachStartingDeliveryFee') }}
              </div>
            </template>
            <template v-else-if="!selectedTime">
              <div>
                {{ $t('plsSelectReservationTime') }}
              </div>
            </template>
            <div v-else>
              <div
                  style="font-size: 16px !important;text-transform: capitalize;font-weight: 500;letter-spacing: 0"
              >
                {{ $t('plsEditAddress') }}
              </div>
              <div
                  class="mt-2"
                  style="font-size: 12px !important;text-transform: capitalize;font-weight: 300;letter-spacing: 0"
              >
                {{ $t('Klicken, um die Bestellsdetails zu ändern') }}
              </div>
            </div>
          </v-btn>
        </template>

      </div>
      <div style="height: 160px;width: 100%"></div>
    </div>

    <v-bottom-sheet v-model="sheet" width="100%">
      <v-sheet class="pa-4 text-body-2" style="position: relative">

        <div>
          <div class="text-caption font-weight-bold mb-2">{{ $t('Preise sind inkl.MwSt.') }}</div>
        </div>
        <div class="d-flex justify-space-between">
          <span>{{ $t('Gerichte insgesamt') }}</span><span>{{ totalPriceWithoutMod | priceDisplay }} €</span>
        </div>
        <div v-if="discount>0" class="d-flex justify-space-between">
          <span>{{ $t('discount') }}</span><span>- {{ discount | priceDisplay }} €</span>
        </div>
        <div v-if="deliveryCost > 0 && !canArriveFreePrice" class="d-flex justify-space-between">
          <span>{{ $t('Liefergebühr') }}</span><span>{{ deliveryCost | priceDisplay }} €</span>
        </div>
        <div v-if="deliveryCost > 0 && canArriveFreePrice" class=" d-flex justify-space-between">
          <span>{{ $t('Liefergebühr') }}</span>
          <div> {{ $t("freeDeliveryFee") }}<span class="ml-2" style=" text-decoration: line-through;">{{
              deliveryCost | priceDisplay
            }} €</span></div>
        </div>
        <div class=" d-flex justify-space-between align-center">
          <span>{{ $t('Gesamtbetrag') }}</span>
          <span>{{ totalPrice | priceDisplay }} €</span>
        </div>
        <div class="my-2 text-caption font-weight-bold">
          {{ $t('Paymentmethode') }}
        </div>
        <div>
          <v-card
              @click="sendOrder(p.id,false)" width="100%" elevation="0" color="grey lighten-4" :key="p.id"
              class="pa-3 mb-2"
              v-for="p in paymentMethodWithoutPayPal"
          >
            {{ p.name }}
          </v-card>
        </div>
        <div ref="button"></div>
        <div
            class="d-flex align-center justify-center white"
            style="position: absolute;left: 0;right: 0;top: 0;bottom: 0;z-index: 2" v-if="loading"
        >
          <v-progress-circular indeterminate></v-progress-circular>
        </div>
      </v-sheet>
    </v-bottom-sheet>
    <address-selector
        @save="save"
        v-model="showAddressSelector"
    />
    <v-bottom-sheet v-model="showCommentArea" width="100%">
      <v-card class="pa-4" color="grey lighten-4" tile>
        <v-textarea
            v-model="commentStr" hide-details height="100"
            :placeholder="$t('Schreibe eine Anmerkung (wie besondere Anfragen, Allergien, Ernährungseinschränkungen usw.)...')"
        ></v-textarea>
        <v-btn
            class="mt-4" @click="confirmNote" color="primary lighten-4 black--text"
            elevation="0" block
        >OK
        </v-btn>
      </v-card>
    </v-bottom-sheet>
    <v-bottom-sheet v-model="showDiscountDialog" width="100%">
      <v-card class="pa-4" color="grey lighten-4" tile>
        <v-text-field v-model="discountCode" hide-details></v-text-field>
        <v-btn
            class="mt-4" @click="confirmDiscount" color="primary lighten-4 black--text"
            elevation="0" block
        >OK
        </v-btn>
      </v-card>
    </v-bottom-sheet>
    <v-bottom-sheet v-model="showDateArea" width="100%" persistent>
      <v-card class="pa-6" tile>
        <div class="mt-1 text-h6 mb-12 font-weight-black">
          Bitte Zeit Wälhen
        </div>
        <div>
          <v-select outlined v-model="selectedDay" :items="futureWeek" item-text="text" item-value="value"></v-select>
          <v-select outlined v-model="selectedTime" return-object :items="showTime"></v-select>
        </div>
        <v-btn
            :disabled="!selectedTime" @click="confirmDate" x-large color="primary lighten-4 black--text"
            elevation="0" block
        >OK
        </v-btn>
      </v-card>
    </v-bottom-sheet>
  </div>

</template>
<script>
import CartService from '@/dataLayer/service/CartService'
import {defaultRestaurantInfo, getCurrentRestaurantInfo} from '@/dataLayer/repository/restaurantInfo'
import {calculateDiscountPrice} from 'aaden-base-model/lib/Models/AadenBase'
import EditDishCard from "@/components/EditDishCard"
import AddressSelector from '@/pages/widget/adress/AddressSelector'
import {checkVoucher, DeliveryMethod, submitOrder} from '@/dataLayer/service/OrderService'
import {getFutureWeek, getOpeningTimeForWeekDay, getRestaurantInfo} from '@/dataLayer/service/api'
import GlobalConfig from "@/dataLayer/repository/GlobalSettingManager"
import IKUtils from "innerken-js-utils"
import dayjs from "dayjs"
import {OrderInfo} from '@/dataLayer/repository/currentOrderState'
import {ContactAddress} from '@/dataLayer/repository/localAddressInfo'
import {loadScript} from '@paypal/paypal-js'
import {endLocale} from "@/main";

const version = require('../../package.json').version
export default {
  name: "CheckoutPage",
  components: {AddressSelector, EditDishCard},
  data: function () {
    return {
      sheet: false,
      version,
      targetDay: 0,
      startPrice: 0,
      freePrice: 0,
      showDateArea: true,
      selectedDay: 0,
      futureWeek: getFutureWeek(),
      showTime: [],
      selectedTime: null,
      currentDish: null,
      paymentPanel: [0],
      timeSelectPanel: [0],
      showCommentArea: false,
      showPaymentArea: true,
      showDishDialog: false,
      paymentList: [],
      payMethodId: null,
      commentStr: '',
      cart: CartService,
      restaurantInfo: defaultRestaurantInfo,
      deliveryCost: 0,
      discountStr: '',
      showAddressSelector: false,
      note: '',
      deliveryMethod: null,
      addressInfo: null,
      DeliveryMethod,
      GlobalConfig,
      loading: false,
      showDiscountDialog: null,
      discountCode: '',
    }
  },
  watch: {
    selectedDay: {
      immediate: true,
      async handler(val) {

        const targetWeekday = this.getTargetDay(val)
        this.targetDay = targetWeekday
        this.showTime = []
        this.showTime.push(...(await getOpeningTimeForWeekDay(targetWeekday)))
        this.selectedTime = null

      }
    }
  },
  computed: {
    differentPrice: function () {
      return this.totalPriceWithoutMod - this.startPrice
    },
    canArriveFreePrice: function () {
      return parseFloat(this.freePrice) !== 0 && (this.totalPriceWithoutMod - this.freePrice) > 0
    },
    dishCount: function () {
      return this.cartList.length > 0 ? this.cart.count() : 0
    },
    cartList: function () {
      return this.cart.list?.length > 0 ? this.cart.list.filter(i => i.count > 0) : []
    },
    totalPriceWithoutMod: function () {
      return this.cartList.length > 0 ? this.cart.total() : 0
    },
    totalPriceBeforeDiscount: function () {
      if (this.canArriveFreePrice) {
        return parseFloat(this.totalPriceWithoutMod)
      } else {
        return parseFloat(this.deliveryCost) + parseFloat(this.totalPriceWithoutMod)
      }
    },
    discount: function () {
      if (this.discountStr) {
        return calculateDiscountPrice(this.totalPriceWithoutMod, this.discountStr)
      } else {
        return 0
      }
    },
    isPickUp: function () {
      return this.deliveryMethod === DeliveryMethod.pickup
    },
    canOrder: function () {
      return this.dishCount > 0 &&
          this.addressInfo &&
          (this.isPickUp || (this.differentPrice >= 0))
          && this.selectedTime

    },
    totalPrice: function () {
      return this.totalPriceBeforeDiscount - parseFloat(this.discount)
    },
    selectedPayMethod: function () {
      return this.paymentList.find(p => p.id === this.payMethodId) ?? {name: 'Nichts'}
    },
    activePayMethodIsPaypal: function () {
      return this.selectedPayMethod.name.toLowerCase().includes('paypal')
    },
    paymentMethodWithoutPayPal: function () {
      return this.paymentList.filter(p => !p.name.toLowerCase().includes('paypal'))
    },
    paypalCredentials: function () {
      const paypal = this.paypalPaymentMethod
      return paypal ? {
        sandbox: 'AbBmj6XYaYu5X42wLdIrUMYmBUWTknOO3ikhMA_OihWFBJe-D4g-AGEG-kho6ASwvEv4bNIF57XC1TeR',
        production: paypal.apiKey
      } : null
    },
    paypalPaymentMethod() {
      console.log(this.paymentList)
      return this.paymentList.find(p => p.name.toLowerCase().includes('paypal'))
    },
    paypalApiKey() {
      return this.paypalCredentials?.production
    }
  },
  methods: {
    async showPaymentMethod() {
      this.loading = true
      this.sheet = true
      await this.$nextTick()
      if (this.paypalApiKey) {
        const paypalId = this.paypalPaymentMethod?.id
        const totalPrice = this.totalPrice.toFixed(2)
        const success = (extraInfo = {}) => {
          this.sendOrder(paypalId, true, extraInfo)
        }
        try {
          this.$refs.button.innerHTML = ''
          const paypal = await loadScript({
            "client-id": this.paypalApiKey,
            currency: "EUR",
            components: "buttons,funding-eligibility",
            "enable-funding": "ideal"
          })
          await paypal.Buttons({
            style: {
              label: 'pay'
            },
            createOrder(data, actions) {
              return actions.order.create({
                intent: 'CAPTURE',
                application_context: {
                  brand_name: 'Aaden POS',
                  shipping_preference: 'NO_SHIPPING',
                },
                purchase_units: [{
                  amount: {
                    value: totalPrice,
                    currency_code: 'EUR'
                  }
                }]
              })
            },
            async onApprove(data, actions) {
              const orderId = data.orderID
              const paymentId = data.paymentID
              const res = await actions.order.capture()
              if (res.status === "COMPLETED" || res.status === "APPROVED") {
                success({
                  orderId, paymentId,
                  ...res
                })
              } else {
                alert("Payment Failed")
              }

            }
          }).render(this.$refs.button)
        } catch (e) {
          console.warn(e)
        }
      }
      this.loading = false


    },
    confirmDate() {
      if (this.selectedTime && this.selectedTime) {
        this.showDateArea = false
      }
    },
    async confirmDiscount() {
      const discountStr = await checkVoucher(this.discountCode)
      this.discountCode = ""
      this.discountStr = discountStr
      this.showDiscountDialog = false
    },
    confirmNote() {
      this.showCommentArea = false
      this.note = this.commentStr
    },
    getTargetDay: function (originDay) {
      let targetWeekday = dayjs().add(originDay, 'd').day()
      if (targetWeekday === 0) {
        targetWeekday = 7
      }
      return targetWeekday
    },
    save(addressInfo) {
      OrderInfo.address = addressInfo
      OrderInfo.deliveryRule = addressInfo.deliveryRule
      OrderInfo.deliveryMethod = addressInfo.deliveryMethod


      this.updateAllInfo()
      this.showAddressSelector = false
    },
    updateAllInfo() {
      this.addressInfo = OrderInfo.address
      this.deliveryMethod = OrderInfo.deliveryMethod
      this.deliveryCost = 0
      this.freePrice = 0
      this.startPrice = 0
      if (OrderInfo.deliveryRule) {
        const rule = OrderInfo.deliveryRule
        if (rule.priceMod) {
          this.deliveryCost = rule.priceMod
        }
        this.startPrice = rule.startPrice
        this.freePrice = rule.freePrice
      }
      const isPickUp = OrderInfo.deliveryMethod === DeliveryMethod.pickup
      if (isPickUp) {
        this.discountStr = GlobalConfig.pickDiscountStr
      } else {
        this.discountStr = ""
      }
    },

    async sendOrder(payMethodId, paid = false, extraInfo = {}) {
      this.loading = true
      this.payMethodId = payMethodId
      const targetInfo = new ContactAddress(this.addressInfo)
      targetInfo.note = this.note.replaceAll(`\n`, '<BR>')
          .replaceAll("'", '-')
      targetInfo.date = dayjs().add(this.selectedDay, 'd').format('YYYY-MM-DD')
      targetInfo.time = this.selectedTime
      if (this.addressInfo.addressLine1) {
        targetInfo.addressLine1 = this.addressInfo.addressLine1.replaceAll("'", '-')
      }
      if (this.addressInfo.addressLine2) {
        targetInfo.addressLine2 = this.addressInfo.addressLine2.replaceAll("'", '-')
      }
      targetInfo.metaData = {
        takeawayWebVersion: 'new',
        version,
        paid
      }
      try {
        await submitOrder(this.cartList, Object.assign({}, targetInfo, {
          deliveryMethod: this.isPickUp ? DeliveryMethod.pickup : DeliveryMethod.delivery,
          discountStr: this.discountStr,
          payMethodId: this.payMethodId,
          deliveryCost: this.canArriveFreePrice ? 0 : this.deliveryCost,
          paid,
          extraInfo,
        }), this.totalPriceBeforeDiscount, paid)

        this.cart.clear()
        await this.$router.push('/complete')
      } catch (e) {
        IKUtils.showError(e, 'Submit Error, please submit again!')
        console.log(e)
      }
      this.loading = false

    }
  },
  async mounted() {
    this.targetDay = this.getTargetDay(this.selectedDay)

    this.restaurantInfo = await getCurrentRestaurantInfo()
    this.paymentList = (await getRestaurantInfo(endLocale, 'payMethod')).filter(p => parseInt(p.isOnline) === 1)
    this.paymentList.forEach(item => {
      item.name = item.langs.name
    })
    this.updateAllInfo()
    if (this.dishCount === 0) {
      this.$router.push('/menu')
    }


  }
}
</script>

<style scoped>

</style>
