import hillo from 'hillo'
import GlobalConfig from '@/dataLayer/repository/GlobalSettingManager'

export const DeliveryMethod = {
    pickup: 'Zum Mitnehmen',
    delivery: 'Lieferung'
}


export async function submitOrder(orderList, addressInfo, totalPrice, paid = false) {

    const paramData = {
        order: orderList,
        addressInfo: addressInfo,
        paymentState: paid ? 1 : 0
    }
    return await hillo.jsonPost(GlobalConfig.cloudRoot + '/orders/create', {
        jsonRequest: JSON.stringify(paramData),
        dataTerminalId: GlobalConfig.dataTerminalId,
        totalPrice,
        paymentState: paid ? 1 : 0
    })

}

export async function checkVoucher(code) {
    let result=""
    try {
        const res= await hillo.get(GlobalConfig.cloudRoot + '/voucher/find', {
            dataTerminalId: GlobalConfig.dataTerminalId,
            code
        })
        result=res.discountStr
    }catch (e) {
      console.log(e)
    }
    return result

}
