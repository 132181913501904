
  <template>
    <div>
      <v-card tile color="grey" style="width: 100%;height: 48px"></v-card>
      <div
          style="height: 80vh"
          class="d-flex align-center justify-center flex-column"
      >
        <div class="mt-4 font-weight-black text-h6">
          Sorry,The website does not exist
        </div>
        <div class="mt-0 text-body-1">
          Please enter the correct merchant website domain name
        </div>
        <p>Made with ❤️ @InnerKen</p>
      </div>
    </div>
  </template>

<script>


export default {
  name: "ErrorPage",
  async mounted() {
  }
}
</script>

<style scoped>

</style>
