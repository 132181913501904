<template>
  <div>
    <template>
      <v-bottom-sheet width="100vw" max-width="600px" v-model="show" class="dishDetailSheet" persistent>
        <v-card
            v-if="dish" id="card"
            style="border-top-right-radius: 16px;border-top-left-radius: 16px;position: relative;overflow: scroll"
            max-height="calc(100vh - 24px)"
        >
          <v-img
              v-if="dish&&hasImage"
              style="border-top-right-radius: 16px;border-top-left-radius: 16px" height="235"
              :src="imageUrl"
          ></v-img>
          <v-btn @click="cancel" style="position: absolute;right: 16px;top:16px;z-index: 5;background: white" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-divider></v-divider>
          <div class="contentContainer">
            <div class="name pr-6">{{ dish.code }}.{{ dish.dishName }}</div>
            <div class="dishPrice mt-2">{{ dish.price }} €</div>
            <div class="dishDescDetail" v-html="dish.dishDesc"></div>
          </div>
          <template v-if="dish.modInfo && mod && show">
            <v-divider></v-divider>
            <div class="contentContainer">
              <template v-for="item in computedOption">
                <h4 :key="'mod2head'+item.id">{{
                    `${item.name}${item.required === '1' ? `:
${item.select[0].text}` : ``}`
                  }}</h4>
                <v-sheet class="pa-1" :key="'mod2'+item.id">
                  <v-item-group
                      v-model="mod[item.id]"
                      :mandatory="item.required==='1'"
                      :multiple="item.multiSelect==='1'"
                      active-class="active"
                  >
                    <v-item :key="'mod111'+index" v-for="(s,index) in item.select" v-slot="{active,toggle}">
                      <v-chip @click="toggle" :ripple="false" small class="ma-1">
                        {{ s.text }}<span>{{ s.priceInfo }}</span>
                      </v-chip>
                    </v-item>
                  </v-item-group>
                </v-sheet>
              </template>
            </div>
          </template>
          <div style="height: 112px;width: 100%"></div>
          <v-app-bar
              max-width="600px" style="position: fixed;margin: auto" scroll-target="#card" elevate-on-scroll bottom
          >
            <div class="infoButton d-flex align-center">
              <v-btn @click="count>0?count--:null" icon>
                <v-icon color="#009de0" small>mdi-minus</v-icon>
              </v-btn>
              {{ count }}
              <v-btn icon @click="count++">
                <v-icon color="#009de0" small>mdi-plus</v-icon>
              </v-btn>
            </div>
            <v-spacer></v-spacer>
            <v-btn
                @click="submitModification" large dark class="mainButton fill-height d-flex align-center justify-center"
            >
              <v-icon left>mdi-basket-plus</v-icon>
              <div
                  style="
          -webkit-font-smoothing: antialiased;
          text-rendering: optimizelegibility;
          font-family: system-ui, -apple-system, BlinkMacSystemFont, Roboto,  sans-serif;
          font-size: 1rem;
          line-height: 1.5rem;
          padding: 0 24px;
          font-style: normal;
          font-stretch: normal;
          text-transform: none;
          font-weight: 700;"
              >
                {{ realPrice * count | priceDisplay }} €
              </div>
            </v-btn>
          </v-app-bar>
        </v-card>
      </v-bottom-sheet>

    </template>
  </div>
</template>

<script>
export default {
  name: "DishDetailSheet",
  props: {show: {}, dish: {}, imageRoot: {}},
  data: function () {
    return {
      selected: false,
      mod: {},
      count: 1,
    };
  },
  computed: {
    options() {
      return this.dish.modInfo ?? []
    },
    hasImage() {
      const image = this.dish.image
      return this.dish.cloudImage ?? (image && ['.png', 'jpg', 'jpeg'].some(it => image.toLowerCase().endsWith(it)))
    },
    imageUrl() {
      return this.dish.cloudImage ?? (this.imageRoot + this.dish.image)
    },
    computedOption: function () {
      const realModInfo = []
      this.options.forEach(item => {
        item.select = []
        if (!Array.isArray(item.selectName)) {
          item.selectName = item.selectName.split(',')
          item.selectValue = item.selectValue.split(',')
          item.priceInfo = item.priceInfo.split(',')
        }
        item.selectName.forEach((name, index) => {
          item.select.push({
            text: `${name}`,
            value: item.selectValue[index],
            price: parseFloat(item.priceInfo[index]),
            priceInfo: parseFloat(item.priceInfo[index]) === 0 ? '' : `(€${parseFloat(item.priceInfo[index]).toFixed(2)})`
          })
        })
        realModInfo.push(item)
      })
      return realModInfo
    },
    realMod: function () {
      const realMod = []
      for (const groupId in this.mod) {
        for (const selectIndex of [this.mod[groupId]].flat()) {
          realMod.push({
            groupId,
            selectIndex
          })
        }
      }
      return realMod
    },
    addPrice: function () {
      return this.computedOption.length > 0 ? this.realMod.reduce((total, i) => {
        total += this.findModItemUseGroupIdAndIndex(i.groupId, i.selectIndex)?.price ?? 0
        return total
      }, 0) ?? 0 : 0
    },
    realPrice: function () {
      return parseFloat(this.dish.price) + parseFloat(this.addPrice)
    },
    realShow: {
      get: function () {
        return this.show
      },
      set: function () {
        this.cancel()
      }
    }
  },
  methods: {
    findModItemUseGroupIdAndIndex(groupId, index) {
      return this.computedOption.find(g => parseInt(g.id) === parseInt(groupId)).select[parseInt(index)]
    },
    cancel() {
      this.$emit('modification-cancel')
      console.log(this.mod)
      this.mod = {}
      console.log(this.mod, 'after')
      this.count = 1
    },
    submitModification() {
      this.$emit('modification-submit', this.mod, this.count)
      this.mod = {}
      this.count = 1
    }
  }
}
</script>

<style scoped>
.contentContainer {
  padding: 16px;
}

.active {
  background: #009de0 !important;
  color: white !important;
}

.name {
  font-feature-settings: "kern", "ss01", "ss05", "ss07";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Roboto, "Open Sans", sans-serif;
  font-variant-ligatures: common-ligatures;
  font-size: 1.75rem;
  line-height: 2.5rem;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  color: rgb(32, 33, 37);
  margin: 0px;
}

</style>
