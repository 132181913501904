<template>
  <div>
    <v-lazy v-if="dish.count !== 0" min-height="48">
      <v-card elevation="0" class="pa-2 text-body-2 mb-1" color="grey lighten-5">
        <div class="d-flex align-baseline">
          <span class="font-weight-black text-no-wrap">{{ dish.count }} *</span>
          <div class="text-body-2 mr-1">
            {{ dish.code }}. {{ dish.dishName }}
          </div>
          <v-spacer></v-spacer>
          <div class="dishPrice text-no-wrap mr-1">{{ dish.realPrice | priceDisplay }} €</div>
          <v-btn class="grey lighten-3" @click="dish.count>0?dish.count--:null" icon>
            <v-icon small>mdi-minus</v-icon>
          </v-btn>
          <v-btn  class="grey lighten-3" icon @click="dish.count++">
            <v-icon small>mdi-plus</v-icon>
          </v-btn>
        </div>
        <div class="text-body-2">
          {{dishModStr}}
        </div>

      </v-card>
    </v-lazy>
  </div>
</template>

<script>
export default {
  name: "EditDishCard",
  props: {
    imageRoot: {},
    dish: {}
  },
  computed: {
    dishModStr: function () {
      return this.dish.displayApply.map((i) => i.groupName + ':' + i.value).join(', ')
    }
  },
  components: {}
}
</script>

<style scoped>

</style>
