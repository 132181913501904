import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import i18n from './i18n'
import { loadConfig } from '@/dataLayer/repository/GlobalSettingManager'
import {BrowserTracing} from "@sentry/tracing";
import * as Sentry from "@sentry/vue";

Vue.config.productionTip = false

Vue.filter('priceDisplay',
  function (price) {
    return parseFloat(price).toFixed(2).replace('.', ',')
  }
)
const endLanguage = ['en', 'de', 'zh']
export let endLocale = ''
if (!endLanguage.includes(i18n.locale)){
  endLocale = 'de'
} else {
  endLocale = i18n.locale
}

Sentry.init({
  Vue,
  dsn: "https://487bcbc493fa487db1be38c73a63e517@o4504202529734656.ingest.sentry.io/4504202530783232",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: ["localhost", "my-site-url.com", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

async function init(){
  await loadConfig()
  new Vue({
    vuetify,
    router,
    i18n,
    render: h => h(App)
  }).$mount('#app')
}

init()

