import Vue from 'vue'
import VueRouter from 'vue-router'
import MainPage from '@/pages/MainPage'
import CheckoutPage from '@/pages/CheckoutPage'
import OrderComplete from "@/pages/OrderComplete";
import LoadingPage from "@/pages/LoadingPage";
import NotPayPage from "@/pages/NotPayPage";
import ErrorPage from "../pages/ErrorPage";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'loading',
        component: LoadingPage
    },
    {
        path: '/menu',
        name: 'Menu',
        component: MainPage
    },
    {
        path: '/checkout',
        name: 'Checkout',
        component: CheckoutPage
    },
    {
        path: '/complete',
        name: 'Complete',
        component: OrderComplete
    },
    {
        path: '/notPaid',
        name: 'notPaid',
        component: NotPayPage
    },
    {
        path: '/Error',
        name: 'Error',
        component: ErrorPage
    }
]

const router = new VueRouter({
    scrollBehavior() {
        return {x: 0, y: 0};
    },
    routes
})

export default router
