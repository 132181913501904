<template>
  <div style="height: 100%">
    <div class="d-flex flex-column justify-center align-center" style="height: calc(100%)">
      <lottie-animation v-cloak
                        style="max-width: 200px"
                        :autoPlay="false"
                        ref="animation"
                        :animationData="require('@/assets/error.json')"></lottie-animation>
      <v-fade-transition>
        <div class="pa-4 mt-12 d-flex flex-column align-center text-center" v-if="show">
          <div class="text-h4">糟糕！</div>
          <div class="text-body-1 mt-4">该门店暂未拥有扫码功能,请前往中台购买！</div>
          <div class="text-body-1 mt-2">用 ❤️ @InnerKen 制作</div>
          <v-btn @click="back" class="primary mt-4 elevation-0" style="border-radius: 36px" width="100%">重试</v-btn>
        </div>
      </v-fade-transition>
    </div>

  </div>
</template>

<script>
import LottieAnimation from 'lottie-web-vue' // import lottie-web-vue

export default {
  components: {LottieAnimation},
  name: "NotPayPage",
  data: function () {
    return {show: false};
  },
  mounted() {
    setTimeout(() => {
      this.$refs.animation.play()
      setTimeout(() => {
        this.show = true
      }, 1000)
    }, 1000)
  },
  methods: {
    back () {
      this.$router.push({name: 'loading'})
    }
  },
}
</script>

<style scoped>

</style>
