import {loadBaseConfig} from 'aaden-base-model/lib/Models/GlobalSettings'
import {checkNewRecords, checkOldRecords} from "@/plugins/fire-base";
import {getBaseAndUrlForDeviceId} from "@/dataLayer/service/api";
import router from "../../router";
import dayjs from "dayjs";
import hillo from "hillo";
import {getCurrentRestaurantInfo} from "@/dataLayer/repository/restaurantInfo";

const defaultConfig = require('@/assets/config.json')
let GlobalConfig = Object.assign({}, defaultConfig)
window.Config = GlobalConfig


export async function loadConfig() {
    const shortName = location.hostname.split('.')[0]
    const newRecords = (await checkNewRecords()).find(s => s.subdomainName === shortName)
    const oldRecords = (await checkOldRecords()).find(s => s.subdomainName === shortName)
    let records
    if (newRecords) {
        records = newRecords
        GlobalConfig.SaaSVersion = true
    } else {
        records = oldRecords
    }
    GlobalConfig = Object.assign(GlobalConfig, await loadBaseConfig(defaultConfig))
    console.log(records)
    if (records) {
        GlobalConfig.dataTerminalId = records.dataTerminalId
        GlobalConfig.deviceId = records.deviceId
        GlobalConfig.shopHomepageUrl = records.shopHomepageUrl
        GlobalConfig.hideDelivery = records?.hideDelivery === 'true' ?? false
        GlobalConfig.shopHomepageUrl = records.shopHomepageUrl ? 'http://' + records.shopHomepageUrl : "http://www.aaden-pos.com/en"
        GlobalConfig.pickDiscountStr = records.pickDiscountStr ? records.pickDiscountStr : ''
        if (records.bannerImagePath) {
            GlobalConfig.bannerPath = records.bannerImagePath
        }
    } else {
        await router.push('Error')
    }
    const realUrl = (await getBaseAndUrlForDeviceId(records?.deviceId ?? GlobalConfig.deviceId)).url
    GlobalConfig.imageRoot = getImgRoot(realUrl);
    GlobalConfig.resourceRoot = realUrl + "/Resource/";
    try {
        await reportToCloud({
            deviceId: GlobalConfig.deviceId,
            ip: "",
            uuid: location.host,
            version: require("../../../package.json").version,
            frontendType: "takeaway-1.1",
            name: (await getCurrentRestaurantInfo()).name,
        })
    } catch (e) {
        console.log(e)
    }
}

export function getImgRoot(base) {
    return base + GlobalConfig.imgRoot;
}

export async function reportToCloud(data) {
    const defaultData = {
        name: '', ip: '', uuid: '', version: '', frontendType: '', deviceId: '',
    };
    const {
        name,
        ip,
        uuid,
        version,
        frontendType,
        deviceId,
    } = Object.assign({}, defaultData, data);
    const frontendLogDTO = {
        name,
        ip,
        uuid,
        version,
        frontendType,
        deviceId,
        timestamp: dayjs().valueOf()
    };
    await hillo.jsonPost("https://cloud-v2.aaden.io/api/frontend-logs/save", frontendLogDTO)
}


export default GlobalConfig
